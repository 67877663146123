import React from "react";
import { Route, Routes } from "react-router-dom";
// internal
import BackToTop from "../components/BackToTop";
import ContextProvider from "../components/context/ContextProvider";
import Home from "../components/Home/Home";
import HomeThree from "../components/HomeThree/HomeThree";
import HomeTwo from "../components/HomeTwo/HomeTwo";
import About from "../components/Pages/About/About";
import ContactUs from "../components/Pages/ContactUs/ContactUs";
import FAQ from "../components/Pages/FAQ/FAQ";
import Portfolio from "../components/Pages/Portfolio/Portfolio";
import PortfolioDetails from "../components/Pages/PortfolioDetails/PortfolioDetails";
import Price from "../components/Pages/Price/Price";
import Service from "../components/Pages/Service/Service";
import ServiceDetails from "../components/Pages/ServiceDetails/ServiceDetails";
import TeamDetails from "../components/Pages/TeamDetails/TeamDetails";
import TeamPage from "../components/Pages/TeamPage/TeamPage";

const AppNavigation = () => {
  return (
    <ContextProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home-2" element={<HomeTwo />} />
        <Route path="/home-3" element={<HomeThree />} />
        <Route path="/about" element={<About />} />
        <Route path="/team" element={<TeamPage />} />
        <Route path="/team-details" element={<TeamDetails />} />
        <Route path="/service" element={<Service />} />
        <Route path="/service-details" element={<ServiceDetails />} />
        <Route path="/price" element={<Price />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/portfolio-details" element={<PortfolioDetails />} />
      </Routes>
      <BackToTop />
    </ContextProvider>
  );
};

export default AppNavigation;
