import React from "react";
import Footer from "../common/Footer/Footer";
import Menu from "../common/Menu/Menu";
import Hero from "./Hero";
import QuienesSomos from "./QuienesSomos";
import TopAbout from "./TopAbout";
import Services from "./Verticales";
import Presentes from "./Presentes";
import Disenyamos from "./Disenyamos";
import Compania from "./Compania";
import Futuro from "./Futuro";
import QueHacemos from "./QueHacemos";
import Lupa from "./Lupa";
import Juntos from "./Juntos";
import Contact from "./Contact";

const Home = () => {
  return (
    <>
      <Menu />
      <Hero />
      <div
        style={{
          width: "100%",
          overflow: "hidden",
          backgroundColor: "#07081C",
        }}
      >
        <img
          src="assets/img/logo/ondas_azul.png"
          alt="Descriptive text"
          className="img-fluid p-o"
          style={{ width: "100%", display: "block" }}
        />
      </div>
      <Services />
      <QuienesSomos />
      <Presentes />
      <QueHacemos />
      <TopAbout />
      <Lupa />
      <Disenyamos />
      <Futuro />
      <Compania />
      <Juntos />
      <Contact />
      <Footer />
    </>
  );
};

export default Home;
