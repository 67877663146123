import React from "react";

const Compania = () => {
  return (
    <>
      {/* <!-- about area start --> */}
      <div className="tp-about-area pt-140">
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-lg-6">
              <div className="tp-about-right pl-md-40 pl-10">
                <div className="tp-section-wrapper">
                  <h2
                    className="tp-section-tit mb-30"
                    style={{ fontWeight: 500, marginTop: "-10px" }}
                  >
                    Acompañamos tu crecimiento innovador desde el primer día.
                  </h2>
                  <p
                    style={{
                      fontSize: "17px",
                      fontWeight: 400,
                      color: "#1A224C",
                    }}
                  >
                    Ya sea mediante la mejora de procesos existentes, la
                    implementación de nuevas tecnologías o la expansión a nuevos
                    mercados, nuestro enfoque se basa en maximizar el potencial
                    de cualquier modelo de negocio. Desde nuestro HUB de
                    Innovación, impulsamos la creatividad y la colaboración. En
                    Thinkia, impulsamos ideas tanto internas como externas para
                    desarrollar soluciones innovadoras que transforman
                    industrias.
                  </p>
                  <p
                    style={{
                      fontSize: "17px",
                      fontWeight: 400,
                      color: "#1A224C",
                    }}
                    className="pt-10"
                  >
                    Desde nuestro HUB de Innovación participamos en
                    la financiación de proyectos disruptivos, que tengan
                    potencial para revolucionar el mercado. Al combinar
                    recursos, conocimientos y una visión estratégica, ayudamos a
                    llevar estas ideas desde la concepción, pasando por el
                    diseño e implementación, hasta la implantación del modelo en
                    el mercado, asegurando un impacto tangible y duradero en el
                    tiempo.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tp-about-right p-relative">
                <div className="about-img text-md-end pb-100">
                  <img
                    src="assets/img/about/launch.jpg"
                    alt="despega"
                    style={{ borderRadius: "5px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- about about end--> */}
    </>
  );
};

export default Compania;
