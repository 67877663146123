import React from "react";

const Disenyamos = () => {
  return (
    <>
      {/* <!-- about area start --> */}
      <div className="tp-about-area pt-140">
        <div className="container">
          <div className="row pb-60 pb-md-100">
            <div className="col-lg-6">
              <div className="tp-about-right p-relative">
                <div className="about-img text-md-end">
                  <img
                    src="assets/img/about/posit.jpg"
                    alt="posit"
                    style={{ borderRadius: "5px" }}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tp-about-right pl-10 pl-md-40">
                <div className="tp-section-wrapper mt-40 mt-md-0">
                  <h2
                    className="tp-section-tit mb-30"
                    style={{ fontWeight: 500, marginTop: "-10px" }}
                  >
                    Diseñamos soluciones innovadoras que optimizan la
                    experiencia del cliente.
                  </h2>
                  <p
                    style={{
                      fontSize: "17px",
                      fontWeight: 400,
                      color: "#1A224C",
                    }}
                  >
                    Desde una perspectiva Customer Centric, desarrollamos
                    soluciones de consultoría estratégica end to end, que
                    abordan cualquier fase del ciclo de vida del cliente.
                  </p>
                  <p
                    style={{
                      fontSize: "17px",
                      fontWeight: 400,
                      color: "#1A224C",
                    }}
                    className="pt-10"
                  >
                    Llevamos a cabo las prácticas más innovadoras del mercado,
                    que nos permiten identificar problemas y necesidades del
                    cliente, adaptar la solución a los requerimientos de negocio
                    e implantar nuevas experiencias que generen un gran impacto
                    en la cuenta de resultados.
                  </p>
                  <p
                    style={{
                      fontSize: "17px",
                      fontWeight: 400,
                      color: "#1A224C",
                    }}
                    className="pt-10"
                  >
                    Usamos metodologías ágiles y
                    estrategias digitales para implementar las últimas
                    tecnologías. Esto nos permite desarrollar cualquier tipo de
                    producto o aplicación digital, adaptándonos a las
                    necesidades y oportunidades de tu negocio.
                  </p>
                  <p
                    style={{
                      fontSize: "17px",
                      fontWeight: 400,
                      color: "#1A224C",
                    }}
                    className="pt-10"
                  >
                    Nuestro stack
                    tecnológico abarca cualquier tecnología del mercado,
                    haciendo hincapié en aquellas más potentes.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- about about end--> */}
    </>
  );
};

export default Disenyamos;
