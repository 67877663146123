import React from "react";

const TopAbout = () => {
  return (
    <>
      {/* <!-- about area start --> */}
      <div className="tp-about-area pt-140">
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-lg-6">
              <div className="tp-about-right p-relative">
                <div className="about-img text-md-end mb-40 mb-md-0" >
                  <img src="assets/img/about/ojo.jpg" style={{borderRadius: '5px'}} alt="ojo" />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tp-about-right pl-md-40 pl-10">
                <div className="tp-section-wrapper">
                  <h2 className="tp-section-tit mb-30" style={{fontWeight: 500, marginTop: '-10px'}}>
                    El poder de la <span style={{color: "#00AFFF"}}>IA</span>:<br/>
                    <span className="tp-section-subtit" style={{fontSize: '35px', fontWeight: 400}}>La respuesta a las necesidades más exigentes</span>
                  </h2>
                  <p style={{fontSize: '17px', fontWeight:400, color: '#1A224C'}}>
                    En Thinkia, no solo creamos tecnología; creamos un futuro
                    mejor, donde la inteligencia artificial es una fuerza para
                    el bien.
                  </p>
                  <p style={{fontSize: '17px', fontWeight:400, color: '#1A224C'}} className="pt-10">
                    Nuestro compromiso como expertos en IA va más allá de los
                    algoritmos y los datos; nos esforzamos por comprender las
                    necesidades de nuestros clientes y ofrecer soluciones
                    inteligentes y humanas. Estamos en la vanguardia de la
                    innovación, desafiando los límites de lo posible para
                    transformar la forma en que el mundo trabaja, vive y
                    prospera.
                  </p>
                  <p style={{fontSize: '17px', fontWeight:400, color: '#1A224C'}} className="pt-10">
                    Únete a nosotros en este viaje hacia un mañana impulsado por
                    la inteligencia artificial, donde cada desafío es una
                    oportunidad y cada logro es un paso hacia adelante. Juntos,
                    podemos dar forma a un futuro donde las posibilidades son
                    infinitas.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- about about end--> */}
    </>
  );
};

export default TopAbout;
