import React from "react";

const Lupa = () => {
  return (
    <>
      {/* <!-- about area start --> */}
      <div className="tp-about-area pt-140">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-7 p-0 m-0">
              <div className="tp-about-left p-relative">
                <div className="about-img h-100 w-100">
                  <img
                    src="assets/img/about/lupa.jpg"
                    alt="ojo"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              className="col-lg-5 d-flex align-items-center"
              style={{ backgroundColor: "#06071B" }}
            >
              <div className="tp-about-right pl-20" style={{height: '100%'}}>
                <div className="tp-section-wrapper p-20 d-flex flex-column justify-content-evenly" style={{height: '100%'}}>
                  <div>
                    <h2
                      className="tp-section-tit mb-md-2 mb-10 mt-md-0 mt-40 text-white"
                      style={{ fontWeight: 500, fontSize: '27px' }}
                    >
                      <span style={{ color: "#00AFFF" }}>Lideramos</span> la
                      innovación estratégica con soluciones impulsadas por IA
                    </h2>
                    <p
                      style={{
                        fontSize: "17px",
                        fontWeight: 400,
                        color: "#F9F9F9",
                      }}
                    >
                      Diseñamos e implementamos tecnologías innovadoras, con el
                      objetivo de satisfacer las necesidades actuales y futuras
                      del negocio.
                    </p>
                  </div>
                  <div>
                    <h2
                      className="tp-section-tit mb-md-2 mb-10 mt-md-0 mt-40 text-white"
                      style={{ fontWeight: 500, fontSize: '30px' }}
                    >
                      <span style={{ color: "#00AFFF" }}>Revolucionamos </span>
                      "la consultoría" tradicional.
                    </h2>
                    <p
                      style={{
                        fontSize: "17px",
                        fontWeight: 400,
                        color: "#F9F9F9",
                      }}
                    >
                      Acompañamos a nuestros clientes en cualquiera que sea su
                      necesidad abordando todos los momentos del ciclo de vida
                      del producto, de principio a fin, garantizando el éxito.
                    </p>
                  </div>
                  <div>
                    <h2
                      className="tp-section-tit mb-md-2 mb-10 mt-md-0 mt-40 text-white"
                      style={{ fontWeight: 500, fontSize: '28px' }}
                    >
                      <span style={{ color: "#00AFFF" }}>Potenciamos</span> tus
                      ventas online al siguiente nivel
                    </h2>
                    <p
                      className="mb-60 mb-md-0"
                      style={{
                        fontSize: "17px",
                        fontWeight: 400,
                        color: "#F9F9F9",
                      }}
                    >
                      Somos expertos en e-commerce y optimizamos la gestión
                      integral de una tienda online. Ayudamos a las empresas a
                      asegurarse de que la marca esté presente de manera
                      consistente en cada punto de contacto con el cliente,
                      desde el descubrimiento hasta la compra.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- about area end --> */}
    </>
  );
};

export default Lupa;
