import React from "react";

const Hero = () => {
  return (
    <>
      <div
        id="inicio"
        className="tp-lasted-blog pt-140 mt-80 mt-md-0 mb-0"
        style={{ backgroundColor: "#07081C" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="tp-section-wrapper text-center">
                <span
                  className="fw-light"
                  style={{
                    color: "#B5B5B5",
                    fontSize: "22px",
                    fontWeight: 400,
                    marginBottom: 20,
                  }}
                >
                  Innovación sin límites
                </span>
                <h2
                  className="tp-section-title mb-70 text-white"
                  style={{
                    fontWeight: 600,
                    fontSize: "48px",
                    lineHeight: "110%",
                    marginTop: 20,
                  }}
                >
                  Aliados estratégicos en <br /> transformación digital <br />
                  para empresas del futuro
                </h2>
                <a href="#landing-services-section">
                  <button
                    className="contact-button rounded-pill text-white py-2 px-4 mb-0"
                    id="nosotros"
                    style={{ backgroundColor: "#00AFFF", fontWeight: 500 }}
                  >
                    Impulsamos tu negocio
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style>{`
        @media (max-width: 767px) {
          .tp-section-title {
            font-size: 24px !important; /* Reducir el tamaño de la fuente */
            font-weight: 500 !important; /* Reducir el peso de la fuente */
            margin-top: 30px !important; /* Añadir margen superior */
          }
          .contact-button {
            font-size: 16px !important; /* Reducir el tamaño de la fuente del botón */
            margin-top: 40px !important; /* Añadir margen superior */
          }
        }
      `}</style>
    </>
  );
};

export default Hero;
